<!--
 * @Descripttion: 装备列表
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-29 01:35:39
 * @LastEditTime: 2021-10-10 02:09:49
-->
<template>
  <div v-wechat-title="titleName"
       ref='scroll'>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>全区全服</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding:0.5rem">
      <el-button @click="returnPage"
                 size="small"
                 icon="el-icon-arrow-left">上一页</el-button> <br class="pcHidden" />

      <el-select class="select-list-index w_99"
 style="width:150px"
                 size="mini"
                 v-model="query.qf_cid"
                 @change="QuFuChangeQuery"
                 placeholder="区服">
      
        <el-option v-for="item in CategoryList"
                   :key="item.category_id"
                   :label="item.category_name"
                   :value="item.category_id">
        </el-option>
      </el-select>
  <el-select class="select-list-index w_49"
  style="width:90px"
                 size="mini"
                 v-model="query.zz_cid"
                 @change="ZhongZhuChangeQuery"
                 placeholder="种族">
        <el-option label="全部"
                   value="0"></el-option>
        <el-option v-for="item in zhongzu"
                   :key="item.category_id"
                   :label="item.category_name"
                   :value="item.category_id">
        </el-option>
      </el-select>
      <el-select class="select-list-index w_49"
        style="width:90px"
                 size="mini"
                 v-model="query.zy_cid"
                 @change="ZhiYeChangeQuery"
                 placeholder="职业">
        <el-option label="全部"
                   value="0"></el-option>
        <el-option v-for="item in zhiye"
                   :key="item.category_id"
                   :label="item.category_name"
                   :value="item.category_id">
        </el-option>
      </el-select>
    <el-button  type="success" round
                 size="small"
                 @click="gotoUrl('/GameRoleLists')">帐号列表</el-button>
      <div style="
    padding: 5px 0;
    margin: 2px 5px;
    font-size: 12px;
    color: orange;
    border: 1px solid hsla(0,0%,100%,.4);
    background: hsla(0,0%,100%,.4);
    border-radius: 2px;
    text-align: center;
">可注册自己上架</div>

      <div style="width:100%"
           v-for="(item,index) in EquipList"
           :key="index">
        <div class="divTitle">
          {{item.name}}
        </div>
        <div class="flex">
          <div class='gr_list'
               v-for="(zb_info,zbindex) in item.data"
               :key="zbindex">
               
            <div class="gr_list_div"
             :style="zb_info.is_hot===1?'border: 1px solid '+zb_info.color+';box-shadow: inset 0 0 10px 0 '+zb_info.color+';color:'+zb_info.color:'color: #a9a9a9;'">
     
                <div  class="t_div" >
              <div class="divLeft" :style="zb_info.is_hot===1?'width:50%;color:'+zb_info.color:'width:50%;'"> 
                【{{zb_info.zhiye}}】{{zb_info.title}}
              </div>
              <div class="divRight" style="width:auto" >
                   <span class="spanDate" style="font-size:1rem">

               {{zb_info.contact}}</span>
                <span class="spanPrice">￥{{zb_info.price|money}}</span>

            
              </div>
                </div>
            </div>
          </div>

          <!-- <div :id="`anchor${item.equip_id}`"
               @click="goto(item)"
               :class="item.isClick===true?' gr_list_div active':' gr_list_div'"
               :style="item.is_hot===1?'border: 1px solid '+item.color+';box-shadow: inset 0 0 10px 0 '+item.color+';color:'+item.color:'color: #a9a9a9;'">
            <div style="display: flow-root;border-bottom: 1px dashed #cacaca;padding-bottom:0.5rem">
              <div class="divLeft">
                <span class="spanLeft">{{item.equip_id}}-</span>
                <span class="spanLeft">{{item.zhiye}}-</span>
                <span class="spanTitle">{{item.title}}<span style="color:red"
                        v-if="item.imgs==='多图'">【多图】</span></span>
                <img v-if="item.ZH_Type=='QQ账号'"
                     :src="wegameImg"
                     width="18"
                     style="margin-left: 0.4rem;" />
                <img v-if="item.ZH_Type=='盛趣账号'"
                     :src="shengquImg"
                     width="18"
                     style="margin-left: 0.4rem" />
              </div>
              <div class="divRight">
                <span class="spanPrice">￥{{item.price|money}}</span>

                <span class="spanDate"><i class="mHidden">发布日期：</i>{{item.create_time}}</span>
              </div>
            </div>

            <div class="div_contacts">{{item.description}}</div>

          </div> -->

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getCateGory } from "@/api";
import { getEquipList} from "@/api/equip";
export default {
  name: "DbgamewebEquip",

  components: {},

  directives: {},

  data() {
    return {
      titleName: "装备列表",
      query: {
        qf_cid: 26,
      },
      EquipList: [],
      CategoryList: [],
      zhiye: [],
      zhongzu: [],
      loding: true,
    };
  },

  mounted() {
    this.getCateGory();
    this.getZhongZu();
    this.getEquipList();
  },

  methods: {
     gotoUrl(url){
        this.$router.push(url);
    },
      //获获取职业分类列表
    getZhongZu() {
      let params = {
        pid: 24,
        sort_field: "category_id",
        sort_type: "asc",
      };
      //24是职业的ID
      getCateGory(params).then((res) => {
        //res.data.clist.pop(); //移除最后一个数组，全职业
        this.zhongzu = res.data.clist;
      });
    },
    getZhiYe() {
      // console.log(this.query);
      let params = {
        pid: this.query.zz_cid,
        sort_field: "category_id",
        sort_type: "asc",
      };
      //24是职业的ID
      getCateGory(params).then((res) => {
        this.zhiye = res.data.clist;
      });
    },
    getEquipList() {
      this.loding = false;
     

      let params = {
        qf_cid: this.query.qf_cid,
        zy_cid: this.query.zy_cid,
         zz_cid: this.query.zz_cid,
        zhongzu: this.query.zhongzu,
        sort_field: this.query.sort_field,
        sort_type: this.query.sort_type,
      };
      getEquipList(params).then((res) => {
        this.EquipList = res.data.list;
        // console.log( this.GameRoleList)
        this.loding = true;
      });
    },
        ZhongZhuChangeQuery(value) {
      if (value == 0) {
        this.zhiye = "";
        this.query.zy_cid = "";
      } else {
        this.query.zz_cid = value;
        this.query.zy_cid = "";
        this.getZhiYe();
        
      }
      this.getEquipList();
    },
    ZhiYeChangeQuery(value) {
      this.query.zy_cid = value;
      this.getEquipList();
    },
    QuFuChangeQuery(value) {
         this.query.qf_cid = value;
         this.getEquipList();
    },
    returnPage() {
      this.$router.go(-1);
    },
    getCateGory() {
      let params = {
        pid: 23,
        sort_field: "category_id",
        sort_type: "asc",
      };
      //23是游戏分区的ID
      getCateGory(params).then((res) => {
        this.CategoryList = res.data.clist;
      });
    },
  },
  filters: {
    //钩子，过滤器
    money(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ""; //.00预定义小数部分
      var value2Array = value.split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,
          return intPartFormat; // + "." + floatPart + "0";
        } else {
          return intPartFormat; // + "." + floatPart;
        }
      } else {
        return intPartFormat; // + floatPart;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.divTitle {
  padding: 5px 10px 8px;
  margin: 5px;
  color:#d65656;
  font-size: 18px;
  font-weight: bold;
}
.t_div{
  display: flow-root;padding:0.2rem;font-weight: bold;
}

@media screen and (max-width: 768) {
  .w_99{
    width: 99% !important;
  }
  .w_49{
    width: 49% !important;
  }
}
@media screen and (max-width: 1080px) {
.divLeft{
  width: 80%;
}

.t_div{
  font-weight:600;
}
.spanDate{
  // display: none;
}
}
</style>