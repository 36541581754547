/*
 * @Descripttion: 游戏装备操作
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-29 17:55:07
 * @LastEditTime: 2021-10-09 09:48:44
 */
const url = 'index/Equip/'
import {
  $post,
    $get
} from "@/utils"

/**
 * 出售中的游戏装备列表
 * @param {array} params 请求数据
 */
 export function getEquipList(params) {
 
    return $get(url + 'index_list', params)
}
/**
 * 出售中的游戏装备列表
 * @param {array} params 请求数据
 */
 export function getMembertEquipList(params) {

   return $get(url + 'list', params)
}

/**
 * 游戏装备添加
 * @param {array} params 请求数据
 */
 export function addEquip(params) {
    return $post(url + 'add', params)
}
/**
 * 游戏装备编辑
 * @param {array} params 请求数据
 */
 export function editEquip(params) {
    return $post(url + 'edit', params)
}

/**
 * 游戏装备编辑
 * @param {array} params 请求数据
 */
 export function deleEquip(params) {
    return $post(url + 'dele', params)
}/**
 * 游戏装备编辑
 * @param {array} params 请求数据
 */
 export function getEquiInfo(params) {
    return $post(url + 'info', params)
}